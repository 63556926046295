import React, { useContext, useEffect } from "react"

import { Router, Redirect } from "@reach/router"
import Layout from "./layout"
import GlobalContext from "../contexts/GlobalContext"
import StudentsIndex from "./students"
import "./global.scss"
import StudentPage from "./students/student"
import IndexPage from "./indexPage"

const App = ({ data, location }) => {
  const { loading, students, prefixes } = useContext(GlobalContext)
  if (prefixes.value.students !== data.site.siteMetadata.prefixes.students) {
    prefixes.set({ students: data.site.siteMetadata.prefixes.students })
  }
  useEffect(() => {
    loading.set(true)
    fetch("https://script.google.com/macros/s/AKfycbzTf0A5O-YiyLsyfrgxE5lWLRUlLSLjSa-hRpDES0vrFgrppOM/exec")
      .then(r => r.json())
      .then(rd => {
        students.set(rd.map(u => (
          {
            ...u,
            "twitter": u.twitter === "@" ? null : u.twitter,
            "fullname": (u.firstname + (u.lastname === "" ? "" : "・" + u.lastname))
          }
        ))
          .sort((x, y) => x.firstname.localeCompare(y.firstname, "ja")))
        loading.set(false)
      })
  }, [])

  if (location.hostname !== "localhost" && location.host !== "mk.kuzumochi.work" && location.pathname === "/") return <></>
  return (
    <Layout location={location}>
      <Router>
        <StudentPage path={`${prefixes.value.students}/:name`} />
        <StudentsIndex path={`${prefixes.value.students}/`} />
        <IndexPage path="/" />
      </Router>
    </Layout>
  )
}
export default App
