import { Link } from "gatsby"
import React, { useContext } from "react"
import logo from "../images/logo.svg"
import GlobalContext from "../contexts/GlobalContext"
import Loading from "./loading"

const Header = ({ location }) => {
  const { loading, prefixes } = useContext(GlobalContext)
  return (
    <header className={location.pathname === "/" ? "high" : ""}>
      {loading.value ? <Loading showText={false} /> : ""}

      <h1 style={{ margin: 0 }}>
        <Link to="/">
          <img id="logo" alt="Logo" src={logo} />
        </Link>
        <Link to="/pc" className="pclist">PCList</Link>
      </h1>
    </header>
  )
}

Header.defaultProps = {
  siteTitle: "",
}

export default Header
