import React, { useContext, useEffect } from "react"
import { Link } from "@reach/router"
import GlobalContext from "../contexts/GlobalContext"
import SEO from "./seo"
import "./index.scss"
import logo from "../images/logo.png"

const IndexPage = () => {
  const { students, prefixes, tweets } = useContext(GlobalContext)
  useEffect(() => {
    fetch("https://minagaku.z31.web.core.windows.net/twitter.json")
      .then(r => r.json())
      .then(rd => {
        tweets.set(rd)
      })
  }, [])
  return (
    <>
      <SEO title="Home" />
      <div className="home">
        <img src={logo} />
        Coming Soon…
      </div>
    </>
  )
}
export default IndexPage
