import React, { useContext,useState } from "react"
import { Link } from "@reach/router"
import GlobalContext from "../../contexts/GlobalContext"
import SEO from "../seo"
import "./students.scss"
import Fusen from "./fusen"
import Loading from "../loading"
import SideFusenList from "./sideFusenList"
import Wand from "../../images/magic.svg"

const styleTable = {
  "セイバー": { skill: "疾風剣の印", statusFix: [2, 2, 1, 0, 1, 0] },
  "パラディン": { skill: "庇護の印", statusFix: [1, 2, 0, 0, 2, 1] },
  "ルーラー": { skill: "巻き戻しの印", statusFix: [0, 1, 0, 2, 2, 1] },
  "アーチャー": { skill: "光弾の印", statusFix: [1, 1, 2, 0, 2, 0] },
  "キャヴァリアー": { skill: "王騎の印", statusFix: [2, 2, 0, 0, 1, 1] },
  "メサイア": { skill: "救難の印", statusFix: [0, 1, 0, 1, 2, 2] },
  "マローダー": { skill: "暴風の印", statusFix: [2, 1, 2, 0, 1, 0] },
  "パニッシャー": { skill: "破邪の印", statusFix: [1, 1, 1, 0, 2, 1] },
}

const statusSub =
  [
    ["格闘", "力技", "重武器", "水泳", "頑健",],
    ["軽武器", "運動", "隠密", "回避", "騎乗",],
    ["射撃", "手業", "知覚", "霊感",],
    ["治療", "混沌知識", "聖印知識", "軍略知識", "専門知識："],
    ["意志", "聖印",],
    ["話術", "感性", "情報収集", "芸術："],
  ]

function copyText(text) {
  const [clicked,setClicked] = useState(false);
  return [clicked,() => {
    navigator.clipboard.writeText(text)
    setClicked(true)
  }]
}

function renderBr(name) {
  if (name.includes("：")) {
    const val = name.split("：")
    if (val[1] === "") return <>{name}</>
    return <>{val[0]}：<br />{val[1]}</>
  }
  return <>{name}</>
}

function renderCopyButton(st, index, name) {
  const fix1 = [st.status_str, st.status_agi, st.status_feel, st.status_int, st.status_mnd, st.status_emp][index]
  const fix = Math.floor(fix1 / 3) + (styleTable[st.style]?.statusFix[index] ?? 0)
  if (st.skill1.replace("◯◯(次の欄に入力)", st.skill1_ex).startsWith(name)) {
    const val = st.skill1.replace("◯◯(次の欄に入力)", st.skill1_ex)
    const [c,on] = copyText(`/g 3d6+${fix} ${val}`)
    return <div className="three"><button type="button" onClick={on}>{c ? "✔":"" }{renderBr(val)}★</button></div>
  }
  if (st.skill2.replace("◯◯(次の欄に入力)", st.skill2_ex).startsWith(name)) {
    const val = st.skill2.replace("◯◯(次の欄に入力)", st.skill2_ex).split("：")
    const [c,on] = copyText(`/g 3d6+${fix} ${val}`)
    return <div className="three"><button type="button" onClick={on}>{c ? "✔":"" }{renderBr(val)}★</button></div>
  }
  const l = name.split("：")
  const [c,on] = copyText(`/g 2d6+${fix} ${name}`)
  return <div className="two"><button type="button" onClick={on}>{c ? "✔":"" }{renderBr(name)} </button></div>
}

function renderStatusFix(st, index) {
  const value = styleTable[st.style]?.statusFix[index]
  if (!value) return "";
  return "+" + value;
}

function renderFigcaption(cap) {
  if (!cap || cap === "") return ""
  const m = cap.match(/https?:\/\/[\w!?+-_~=;.,*&@#$%()'[]]+/)
  const c = cap.replace(/https?:\/\/[\w!?+-_~=;.,*&@#$%()'[]]+/, "")

  if (m) {
    return <div><a href={m[0]}>出典</a> {c} </div>;
  }
  return <div>出典: {c}</div>
}
const StudentPage = ({ name }) => {
  const { students, prefixes } = useContext(GlobalContext)
  if (students.value.length === 0) {
    return (
      <article key={name} className="student-detail">
        <div className="student-info2">
          <Loading />
        </div>
      </article>
    )
  }
  const st = students.value.find(x => x.fullname === name)
  if (!st) {
    return (
      <div>
        PC
        {name} が見つかりません
      </div>
    )
  }
  return (
    <>
      <SEO title={name} />
      <div className="students student-detail">
        <SideFusenList students={students.value} current={name} prefix={prefixes.value.students} />
        <article key={name} className="student-info">
          <div className="student-info2">
            <h2 style={st.fullname.length >= 15 ? { marginTop: "1em" } : undefined}>
              <Link to={`/${prefixes.value.students}/${st.fullname}`}>{st.fullname}</Link>
              <span className="age">
                {st.age.match(/^\d+$/) ? st.age + "歳" : st.age}/{st.sex}
              </span>
            </h2>
            <div className="fusen-wrapper">
              <Fusen student={st} />
            </div>
            <div className={st.chara_card ? "mini-grid" : "mini-table"}>
              {st.chara_card ? (
                <figure className="chara-card">
                  <img alt="立ち絵" src={st.chara_card} />
                  <figcaption>{renderFigcaption(st.chara_card_by)}{st.chara_card_author !== "" ? `(作者)${st.chara_card_author}` : ""} </figcaption>
                </figure>
              ) : (
                ""
              )}
              <table className="a2">
                <tbody>
                  <tr>
                    <th>所属</th>
                    <td>{st.team}</td>
                  </tr>
                  <tr>
                    <th>出自</th>
                    <td>{st.from}</td>
                  </tr>
                  <tr>
                    <th>経験</th>
                    <td>{st.exp1}</td>
                  </tr>
                  <tr>
                    <th>信念/禁忌</th>
                    <td>{st.taboo}</td>
                  </tr>
                  <tr>
                    <th>信念/目的</th>
                    <td>{st.purpose}</td>
                  </tr>
                  <tr>
                    <th>信念/趣味嗜好</th>
                    <td>{st.hobby}</td>
                  </tr>
                  <tr>
                    <th>誓い1</th>
                    <td>{st.pledge}</td>
                  </tr>
                  <tr>
                    <th>誓い2</th>
                    <td>{st.pledge2}</td>
                  </tr>
                  <tr>
                    <th>誓い3</th>
                    <td>{st.pledge3}</td>
                  </tr>
                </tbody>
              </table>
              <table className="a3">
                <tbody>
                  <tr>
                    <th>指揮官との関係</th>
                    <td>{st.leader_connection}</td>
                  </tr>
                  <tr>
                    <th>指揮官への感情(メイン)</th>
                    <td>{st.leader_emotion_main}</td>
                  </tr>
                  <tr>
                    <th>指揮官への感情(サブ)</th>
                    <td>{st.leader_emotion_sub}</td>
                  </tr>
                  <tr>
                    <th>得意技能1</th>
                    <td>{st.skill1.replace("◯◯(次の欄に入力)", st.skill1_ex)}</td>
                  </tr>
                  <tr>
                    <th>得意技能2</th>
                    <td>{st.skill2.replace("◯◯(次の欄に入力)", st.skill2_ex)}</td>
                  </tr>
                  <tr>
                    <th>武器</th>
                    <td>{st.weapon}</td>
                  </tr>
                  <tr>
                    <th>盾</th>
                    <td>{st.shield}</td>
                  </tr>
                  <tr>
                    <th>鎧</th>
                    <td>{st.armor}</td>
                  </tr>
                  <tr>
                    <th>補助防具</th>
                    <td>{st.armor_sub}</td>
                  </tr>
                  <tr>
                    <th>乗騎</th>
                    <td>{st.ride ? "有" : "無"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h3>外見的特徴</h3>
            <div className="box">{st.app}</div>
            <h3>詳細設定</h3>
            <div className="box">{st.public_note}</div>
            <h3>一人称</h3>
            <div className="box">{st.first_person}</div>
            <h3>二人称</h3>
            <div className="box">{st.second_person}</div>
            <h3>敬称</h3>
            <div className="box">{st.honorific}</div>
            <h3>口調</h3>
            <div className="box">{st.expression}</div>
            <h3>台詞例</h3>
            <div className="box">{st.expression_sample}</div>
            <h3>能力値</h3>
            <div className="box status">
              <table>
                <tbody>
                  <tr>
                    <th></th>
                    <th>筋力</th>
                    <th>反射</th>
                    <th>感覚</th>
                    <th>知力</th>
                    <th>精神</th>
                    <th>共感</th>
                  </tr>
                  <tr>
                    <th><div>基本値</div></th>
                    <td><span className="base-value">{st.status_str}<small>/3=</small></span>{Math.floor(st.status_str / 3)}</td>
                    <td><span className="base-value">{st.status_agi}<small>/3=</small></span>{Math.floor(st.status_agi / 3)}</td>
                    <td><span className="base-value">{st.status_feel}<small>/3=</small></span>{Math.floor(st.status_feel / 3)}</td>
                    <td><span className="base-value">{st.status_int}<small>/3=</small></span>{Math.floor(st.status_int / 3)}</td>
                    <td><span className="base-value">{st.status_mnd}<small>/3=</small></span>{Math.floor(st.status_mnd / 3)}</td>
                    <td><span className="base-value">{st.status_emp}<small>/3=</small></span>{Math.floor(st.status_emp / 3)}</td>
                  </tr>
                  <tr>
                    <th><div>スタイル</div></th>
                    <td><span>{renderStatusFix(st, 0)}</span></td>
                    <td><span>{renderStatusFix(st, 1)}</span></td>
                    <td><span>{renderStatusFix(st, 2)}</span></td>
                    <td><span>{renderStatusFix(st, 3)}</span></td>
                    <td><span>{renderStatusFix(st, 4)}</span></td>
                    <td><span>{renderStatusFix(st, 5)}</span></td>
                  </tr>
                  <tr>
                    <th><div>判定値</div></th>
                    <td><span>{Math.floor(st.status_str / 3) + (styleTable[st.style]?.statusFix[0] ?? 0)}</span></td>
                    <td><span>{Math.floor(st.status_agi / 3) + (styleTable[st.style]?.statusFix[1] ?? 0)}</span></td>
                    <td><span>{Math.floor(st.status_feel / 3) + (styleTable[st.style]?.statusFix[2] ?? 0)}</span></td>
                    <td><span>{Math.floor(st.status_int / 3) + (styleTable[st.style]?.statusFix[3] ?? 0)}</span></td>
                    <td><span>{Math.floor(st.status_mnd / 3) + (styleTable[st.style]?.statusFix[4] ?? 0)}</span></td>
                    <td><span>{Math.floor(st.status_emp / 3) + (styleTable[st.style]?.statusFix[5] ?? 0)}</span></td>
                  </tr><tr className="status-button"><th style={{textCombineUpright: "all"}}>/g</th>
                    {
                      statusSub.map((s, index) => <td key={index}><div>{
                        s.map(x => renderCopyButton(st, index, x))}
                      </div></td>
                      )
                    }
                  </tr>
                </tbody>
              </table>
            </div>
            <h3>天運</h3>
            <div className="box">
              {st.luck}
            </div>
            <h3>スタイル</h3>
            <div className="box">
              {st.style}
              {st.awaking === "2" ? <span className="ability-box">{styleTable[st.style]?.skill}</span> : ""}
            </div>
          </div>
        </article>
      </div>
    </>
  )
}

export default StudentPage
